/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Roboto', sans-serif; */
}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: none;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #3864FF;
  border-radius: 10px;
}

.lineReward .recharts-layer {
  stroke-linejoin: bevel;
}

iframe {
  display: none;
}